import React from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import styled from 'styled-components';

import useTranslation from 'hooks/use-translation';

import Wrapper from 'components/wrapper';
import MetaTags from 'components/meta-tags';
import GlobalFooter from 'components/global-footer-rebrand';
import MarketingHeader from 'marketing/components/marketing-header';
import { PageContentContainer, H1, P } from 'info/terms';

export default function PrivacyPage() {
  const { t } = useTranslation();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down(`sm`));

  return (
    <Wrapper>
      <MetaTags variant='privacy' />
      <MarketingHeader variant='legal' />
      <PageContentContainer isMobile={isMobile}>
        <H1>{t('privacy.privacy', 'Privacy Policy')}</H1>
        <P>{t('privacy.lastUpdated', 'Last updated February 5, 2024')}</P>
        {/* legal text will never be translated, hence the following line */}

        <P>
          <strong>Notice at Collection:</strong> Dutchie collects the categories of personal information listed in the
          below Privacy Policy, including the information that you are providing in a fillable field referencing this
          Privacy Policy. We collect this information to fulfill the purpose for which you are providing the personal
          information, to provide the Services, to communicate with you (including with information about products or
          services that may be relevant to you), or as otherwise necessary or appropriate in connection with the
          fulfillment of Dutchie's legitimate business purposes, and by submitting your personal information you agree
          to the processing of your information in this manner. To learn more, including how to exercise any rights that
          may be available to you, please review the below Privacy Policy.
        </P>
        <P>Welcome to Dutchie!</P>
        <P>
          This Privacy Policy explains how Courier Plus, Inc. and its affiliates (collectively,{' '}
          <strong>"Dutchie</strong>") collects, uses and discloses your Personal Information as part of providing
          certain products and services developed and/or commercialized by Dutchie, including, without limitation, our
          point of sale systems, eCommerce offerings, cashless payment offerings, insurance-related services, hardware,
          APIs, developer accounts, Customer accounts created in connection with our eCommerce and cashless payment
          offerings, and the websites maintained by Dutchie (collectively, the <strong>"Services"</strong>). This Policy
          covers the following categories of data subjects:
        </P>
        <ul>
          <li>
            <P>
              <strong>Dispensaries:</strong> Dispensaries are cannabis retailers that have contracted with Dutchie for
              use of Dutchie's Services or have expressed an interest in using Dutchie's Services.
            </P>
          </li>
          <li>
            <P>
              <strong>Dispensary Employees:</strong> Dispensary Employees are employees of Dispensaries.
            </P>
          </li>
          <li>
            <P>
              <strong>Customers:</strong> Customers are actual or prospective customers of Dispensaries, including their
              primary caregivers, as applicable.
            </P>
          </li>
          <li>
            <P>
              <strong>Website Visitors:</strong> Website Visitors are individuals that visit our websites.
            </P>
          </li>
          <li>
            <P>
              <strong>Business Partners:</strong> Business Partners are third-party business partners of Dutchie,
              including, for example, third-party integration partners.
            </P>
          </li>
        </ul>
        <P>
          Unless a data subject interacts with Dutchie directly (for example, if a prospective Dispensary customer
          submits their Personal Information to Dutchie in order to schedule a demo of the Service, or if a Customer
          places an order at a Dispensary through our website), Dutchie only processes Personal Information on behalf of
          and at the direction of our Dispensary customers, who are responsible for addressing any privacy requests from
          their Customers and other applicable data subjects. If you are a Customer or other data subject who interacts
          with a Dispensary by using our Services, please review the Dispensary's privacy policy and contact them
          directly with any questions.
        </P>
        <P>
          Dutchie interacts with data subjects across North America, and certain areas in which we operate have laws
          requiring us to share specific information and provide certain rights to individuals in those locations.
          Accordingly, this Privacy Policy is structured as a generally applicable Privacy Policy with location-specific
          schedules. To the extent that you are a data subject within one of these locations, please refer to this
          Privacy Policy, as well as the location-specific schedule applicable to you.
        </P>
        <P>
          Before using the Service or submitting any Personal Information to Dutchie, please review this Privacy Policy
          carefully and <a href='#contact-us'>contact us</a> if you have any questions. By using the Service, you agree
          to the practices and specifically the collection, processing, disclosure, use and retention of Personal
          Information described in this Privacy Policy. If you do not agree to this Privacy Policy, please do not access
          our website or otherwise use the Services.
        </P>
        <ul>
          <li>
            <P>Personal Information We Collect</P>
          </li>
          <li>
            <P>How We Use Personal Information</P>
          </li>
          <li>
            <P>Sharing And Disclosure Of Personal Information</P>
          </li>
          <li>
            <P>Children</P>
          </li>
          <li>
            <P>Links To Other Websites</P>
          </li>
          <li>
            <P>Security</P>
          </li>
          <li>
            <P>Your Choices</P>
          </li>
          <li>
            <P>Changes To The Privacy Policy</P>
          </li>
          <li>
            <P>Contact Us</P>
          </li>
          <li>
            <P>Schedule A &ndash; Users in California</P>
          </li>
          <li>
            <P>Schedule B &ndash; Users in Virginia</P>
          </li>
          <li>
            <P>Schedule C &ndash; Users in Colorado</P>
          </li>
          <li>
            <P>Schedule D &ndash; Users in Connecticut</P>
          </li>
          <li>
            <P>Schedule E &ndash; Users in Canada</P>
          </li>
        </ul>
        <P>
          <strong>1. PERSONAL INFORMATION WE COLLECT</strong>
        </P>
        <P>
          When you interact with us through the Service, we collect and combine information and data from you, including
          information that may identify you or otherwise be considered to be "personal information", "personal data" or
          "personally identifiable information" under applicable laws that regulate privacy and data protection ("
          <strong>Personal Information</strong>") as follows:
        </P>
        <P>
          <strong>Personal Information You Provide</strong>: We collect the following categories of Personal Information
          from you when you visit our website, sign up for and use the Services, place an order through the Services, or
          otherwise provide such information to us, provided that the actual Personal Information collected will depend
          upon the nature of your interaction:
        </P>
        <ul>
          <li>
            <P>
              <strong>Identification Information</strong>: We may collect certain Personal Information including,
              without limitation, your name, email address, phone number, mailing/billing addresses, date of birth, and
              government identification documents (e.g., your driver's license, state or provincial identification card,
              or cannabis medical or license card).
            </P>
          </li>
          <li>
            <P>
              <strong>Service-Specific Information:</strong> To the extent that a Dispensary signs up for our cashless
              payments Services, we may collect certain Personal Information in order to underwrite and/or allow our
              third-party payment processing partners to underwrite the Dispensary, including, without limitation, the
              name, address, date of birth, Social Security number, email address, phone number, and government-issued
              of the Dispensary's beneficial owners in order to allow us or our third-party Business Partners to
              underwrite your use of cashless payments and to fulfill continuing KYC/AML obligations while you use the
              Services.
            </P>
          </li>
          <li>
            <P>
              <strong>Financial Information</strong>: For our Dispensaries in the United States, our payment
              processor(s) will collect and store the financial information necessary to process your payments for your
              subscription to our Services, such as your payment card number and authentication details. Please note,
              however, that we store only a tokenized version of such information and do not maintain payment card
              information on our servers. For our Users in Canada, we may process payments directly in compliance with
              Canadian law, and we only store a tokenized and encrypted version of such information for so long as
              reasonably required to comply with regulatory obligations and/or your preferences.
            </P>
          </li>
          <li>
            <P>
              <strong>Communication Information</strong>: We may collect information when you contact us with questions
              or concerns and when you voluntarily respond to questionnaires, surveys or requests for market research
              seeking your opinion and feedback. Providing communication information is optional to you.
            </P>
          </li>
          <li>
            <P>
              <strong>Location Information</strong>: Our Services may use location-based services in order to locate
              you, such as using your zip or postal code or IP address, so that we may verify your location, deliver you
              relevant content based on your location, and share Customer location with the pre-registered delivery
              providers and Dispensaries. For example, when searching for Dispensaries close to you on our website, you
              will be prompted to provide your address.
            </P>
          </li>
          <li>
            <P>
              <strong>Commercial Information</strong>: We may retain a history of the products you browse and/or
              purchase using the Services.
            </P>
          </li>
          <li>
            <P>
              <strong>Health Information</strong>: If a Customer makes available a state issued cannabis medical card,
              registration document to possess cannabis for medical purposes in Canada, or other documentation, we may
              collect certain health information included within such materials.
            </P>
          </li>
          <li>
            <P>
              <strong>Social Media Information</strong>: We have pages on social media sites like Instagram, Facebook,
              Medium, and Twitter ("<strong>Social Media Pages</strong>"). When you interact with our Social Media
              Pages, we will collect Personal Information that you elect to provide to us, such as your contact details.
              In addition, the companies that host our Social Media Pages may provide us with aggregate information and
              analytics regarding the use of our Social Media Pages. Your posts, comments, replies to and mention of
              Dutchie may be publicly visible, depending on privacy settings. We do not have control over the privacy
              and retention of your Personal Information on Social Media Pages which are governed by the privacy
              policies of the parties that operate the platforms that host the Social Media Pages. We have no
              responsibility or liability for the manner in which the operators of the Social Media Pages collect, use,
              disclose, secure or otherwise treat your personal information.
            </P>
          </li>
        </ul>
        <P>
          <strong>Internet Activity Information</strong>: When you visit, use, and interact with the Services, we may
          receive certain information about your visit, use, or interactions. For example, we may monitor the number of
          people that visit the Services, peak hours of visits, which page(s) are visited, the domains our visitors come
          from (e.g., google.com, yahoo.com, etc.), and which browsers people use to access the Services (e.g., Chrome,
          Firefox, Microsoft Internet Explorer, etc.), broad geographical information, and navigation pattern. In
          particular, the following information is created and automatically logged in our systems:
        </P>
        <ul>
          <li>
            <P>
              <strong>Log Information</strong>: Information that your browser automatically sends whenever you visit our
              websites. Log Information includes your Internet Protocol address, browser type and settings, the date and
              time of your request, and how you interacted with the website.
            </P>
          </li>
          <li>
            <P>
              <strong>Cookie and Tracking Technology Information</strong>: Please see the{' '}
              <strong>"Cookies and Tracking Technologies"</strong> section below to learn more about how we use cookies
              and tracking technologies.
            </P>
          </li>
          <li>
            <P>
              <strong>Device Information</strong>: Includes name of the device, operating system, and browser you are
              using. Information collected may depend on the type of device you use and its settings.
            </P>
          </li>
          <li>
            <P>
              <strong>Usage Information</strong>: We collect information about how you use our Services, such as the
              types of content that you view or engage with, the features you use, the actions you take, and the time,
              frequency, and duration of your activities.
            </P>
          </li>
          <li>
            <P>
              <strong>Email Open/Click Information</strong>: We may use pixels in our email campaigns that allow us to
              collect your email and IP address as well as the date and time you open an email or click on any links in
              the email.
            </P>
          </li>
        </ul>
        <P>
          <strong>Personal Information Collected from Other Sources</strong>: We may receive Personal Information, such
          as contact information and marketing preferences, from other sources, such as Dispensaries, third-party
          Business Partners, service providers, and publicly available information.
        </P>
        <P>
          <strong>Cookies and Tracking Technologies</strong>: When you visit or otherwise interact with our Services, we
          may automatically collect information about you using cookies, device identifiers, pixels and similar
          technologies. We use this information for various purposes, including to make the Services function properly,
          understand how you use the Services, personalize your experience, gather usage data on our Services, measure,
          manage, display and customize the advertisements you see within the Services or elsewhere and improve your
          overall experience while using the Services. A "cookie" is a piece of information sent to your browser by a
          website you visit. Cookies can be stored on your computer for different periods of time. Some cookies expire
          after a certain amount of time, or upon logging out (session cookies), others survive after your browser is
          closed until a defined expiration date set in the cookie (as determined by the third party placing it), and
          help recognize your computer when you open your browser and browse the Internet again (persistent cookies).
          For more details on cookies please visit{` `}
          <a href='http://www.allaboutcookies.org/' target='_blank' rel='noreferrer'>
            <span style={{ color: '#0075e0' }}>
              <strong>All About Cookies</strong>
            </span>
          </a>
          .
        </P>
        <P>
          On most web browsers, you will find a "help" section on the toolbar. Please refer to this section for
          information on how to receive a notification when you are receiving a new cookie and how to turn cookies off.
        </P>
        <P>
          Please note that if you limit the ability of websites to set cookies, you may be unable to access certain
          parts of our websites and you may not be able to benefit from the full functionality of our websites.
        </P>
        <P>
          <strong>Analytics</strong>: We use third-party analytics providers who may deploy cookies, pixels and similar
          technologies to the Services in order to allow us to understand usage of the Services as well as the
          performance of advertisements.. For example, we use Google Analytics, a web analytics service provided by
          Google, Inc. which uses cookies to help us analyze how users use the websites and enhance your experience when
          you use our Services. For more information on how Google uses this information, click{` `}
          <a href='http://www.google.com/policies/privacy/partners/' target='_blank' rel='noreferrer'>
            <span style={{ color: '#0075e0' }}>
              <strong>here</strong>
            </span>
          </a>
          .
        </P>
        <P>
          <strong>Personal Information We Process on Behalf of Dispensaries:</strong> When Dispensaries use our
          Services, they may collect Personal Information from their Customers, including, without limitation,
          Customers' names, phone numbers, email addresses, transactional information, payment information, purchase
          history, location information, identification card information and medical cannabis card information. We refer
          to that Personal Information as "Merchant Data" in our agreements with our Dispensary customers. We process
          the Merchant Data under our Dispensary customers' instructions and in accordance with our agreements with our
          Dispensary customers, which requires us to use the Merchant Data for the strict purpose of providing the
          Services to the corresponding Dispensary or as otherwise expressly set forth in the agreement. Dispensaries
          are responsible for the Personal Information they collect through use of the Services, complying with any
          applicable privacy requirements and fulfilling Customer requests with respect to their Personal Information.
        </P>
        <P>
          <strong>2. HOW WE USE PERSONAL INFORMATION </strong>
        </P>
        <P>We may use Personal Information for the following purposes: </P>
        <ul>
          <li>
            <P>To provide, operate, support and improve the Services and our business; </P>
          </li>
          <li>
            <P>To determine your or a Dispensary's eligibility to use the Services</P>
          </li>
          <li>
            <P>For the specific purpose for which you provided it;</P>
          </li>
          <li>
            <P>To respond to your inquiries, comments, feedback, or questions or otherwise communicate with you;</P>
          </li>
          <li>
            <P>To send administrative or transactional information to you;</P>
          </li>
          <li>
            <P>To send you information regarding products or services that we believe may be of interest to you;</P>
          </li>
          <li>
            <P>To analyze how you interact with our Service; </P>
          </li>
          <li>
            <P>To develop new products and services;</P>
          </li>
          <li>
            <P>
              To prevent fraud, criminal activity, or misuses of our Service, and to ensure the security of our IT
              systems, architecture, and networks; and
            </P>
          </li>
          <li>
            <P>
              To comply with legal obligations and legal process and to protect our rights, privacy, safety, or
              property, and/or that of our affiliates, you, or other third parties.
            </P>
          </li>
        </ul>
        <P>
          <strong>Research and Development</strong>: We may aggregate Personal Information and use the aggregated and
          de-identified information to analyze the effectiveness of the Services, to improve and add features to the
          Services, gain anonymous insights into product and brand performance on the Services, and for other similar
          purposes. In addition, from time to time, we may analyze the general behavior and characteristics of data
          subjects and share aggregated information like general statistics with Dispensaries, brands, and other
          prospective business partners.
        </P>
        <P>
          <strong>Interest-Based Advertising and Personalization:</strong> To make your experience on the Services more
          engaging, we may serve your interest-based advertisements and personalized recommendations using cookies,
          pixels and other tracking technologies. This means that you may see advertisements and personalized
          recommendations while using the Services that are tailored to you. The personal information used in
          furtherance of serving interest-based advertising does not include information which on its own identifies
          you, such as your name or e-mail address.
        </P>
        <P>
          <strong>Communications and Marketing</strong>: We and the Dispensaries you interact with through the Services
          may use your Personal Information, such as your email address and telephone number, to contact you to provide
          the Service and tell you about products or services we believe may be of interest to you.
        </P>
        <P>
          By providing your telephone number, you acknowledge and agree that we and/or our Dispensaries may contact you,
          including but not limited to for promotional, informational and other purposes, using SMS text messages,
          cellular or telephone calls, and push notifications (if applicable) even if you have opted in to the US or
          Canada National Do Not Call List, any state equivalent Do Not Call List or the internal Do Not Call List of
          any company, and that such messages and calls may be sent using automatic dialing systems or artificial or
          prerecorded voice messages.
        </P>
        <P>If you would like to opt-out of receiving certain communications, see the "Your Choices" section below.</P>
        <P>
          <strong>3. SHARING AND DISCLOSURE OF PERSONAL INFORMATION</strong>
        </P>
        <P>
          In certain circumstances we may share the categories of Personal Information described above without further
          notice to you, unless required by the law, with the following categories of third parties:
        </P>
        <ul>
          <li>
            <P>
              <strong>Dispensaries</strong>: To provide the Services, we share certain Personal Information with
              Dispensaries and their service providers to enable Dispensaries to make their products available to
              Customers and for the Dispensaries' own uses, including marketing, referrals, or promotions (such uses
              will be governed by a Dispensary's own privacy policy).
            </P>
          </li>
          <li>
            <P>
              <strong>Co-branded Areas</strong>: Certain areas of the Services may be provided to you in association
              with third parties (<strong>"Co-Branded Areas"</strong>) such as promotional partners. If you elect to
              register for products and/or services in Co-Branded Areas, communicate with such third parties or download
              their content or applications through the Services, your Personal Information will be shared with the
              relevant third party. Further, if you sign-in to a Co-Branded Area with your Dutchie account, your
              Personal Information may be disclosed to the identified third parties for that Co-Branded Area. We are not
              responsible for such third party's data collection or practices and you should look to such third party
              privacy policies for more information.
            </P>
          </li>
          <li>
            <P>
              <strong>Vendors and Service Providers</strong>: To assist us in meeting business operations needs and to
              perform certain services and functions, we may share Personal Information with vendors and service
              providers, including providers of hosting services, cloud services, and other information technology
              services providers, email communication software and email newsletter services, advertising and marketing
              services, payment processors, customer relationship management and customer support services, and
              analytics services. Pursuant to our instructions, these parties will access, process, or store Personal
              Information in the course of performing their duties to us.
            </P>
          </li>
          <li>
            <P>
              <strong>Business Transfers</strong>: If we are involved in a merger, acquisition, financing due diligence,
              reorganization, bankruptcy, receivership, dissolution, sale of all or a portion of our assets, or
              transition of service to another provider (collectively and each a<strong>"Transaction"</strong>), your
              Personal Information and other information may be shared in the diligence process with counterparties and
              others assisting with a Transaction and transferred to a successor or affiliate as part of or following
              that Transaction along with other assets.
            </P>
          </li>
          <li>
            <P>
              <strong>Legal Requirements</strong>: If required to do so by law or in the good faith belief that such
              action is necessary to (i) comply with a legal obligation, including to meet national security or law
              enforcement requirements, (ii) protect and defend our rights or property, (iii) prevent fraud, (iv) act in
              urgent circumstances to protect the personal safety of users of the Services, or the public, or (v)
              protect against legal liability.
            </P>
          </li>
          <li>
            <P>
              <strong>Affiliates</strong>: We may share Personal Information with our current and future affiliates,
              meaning an entity that controls, is controlled by, or is under common control with Dutchie. Our affiliates
              may use the Personal Information we share in a manner consistent with this Privacy Policy. Affiliates may
              be located outside the United States of America and/or Canada.
            </P>
          </li>
        </ul>
        <P>Dutchie does not sell your Personal Information.</P>
        <P>
          <strong>4. RETENTION</strong>
        </P>
        <P>
          We retain Personal Information for as long as reasonably necessary to provide the Services and carry out the
          purposes described in this Policy. If necessary, we may retain Personal Information for longer periods of
          time, until set retention periods and deadlines expire, or for instances where we are required to do so in
          accordance with legal, tax and accounting requirements.
        </P>
        <P>
          To determine the appropriate duration of the retention of Personal Information, we consider the amount, nature
          and sensitivity of the Personal Information, the potential risk of harm from unauthorized use or disclosure of
          Personal Information and if we can attain our objectives by other means, as well as our legal, regulatory,
          tax, accounting and other applicable obligations.
        </P>
        <P>
          Once retention of the Personal Information is no longer necessary for the purposes outlined above, we will
          either delete or de-identify the Personal Information or, if this is not possible (for example, because
          Personal Information has been stored in backup archives), then we will securely store the Personal Information
          and isolate it from further processing until deletion or deidentification is possible.
        </P>
        <P>
          <strong>5. DE-IDENTIFIED INFORMATION</strong>
        </P>
        <P>
          We may at times receive or process Personal Information to create deidentified information that can no longer
          reasonably be used to infer information about, or otherwise be linked to, a particular data subject. Where we
          maintain deidentified information, we will maintain and use the information in deidentified form and not
          attempt to reidentify the information, except as required by law.
        </P>
        <P>
          <strong>6. CHILDREN</strong>
        </P>
        <P>
          Our Services are not directed to children who are under the age of 18 or adults under the minimum age of
          possession of cannabis in their jurisdiction. Dutchie does not knowingly collect Personal Information from
          children under the age of 18 or adults under the minimum age of possession of cannabis. If you have reason to
          believe that a child under the age of 18 or adults under the minimum age of possession of cannabis has
          provided Personal Information to Dutchie through the Service please <a href='#contact-us'>contact us</a> and
          we will endeavor to delete that information from our databases.
        </P>
        <P>
          <strong>7. LINKS TO OTHER WEBSITES</strong>
        </P>
        <P>
          The Services may contain links to other websites and services not operated or controlled by Dutchie, including
          Dispensaries and social media services ("<strong>Third Party Services</strong>"). The information that you
          share with Third Party Services will be governed by the specific privacy policies and terms of service of the
          Third Party Services and not by this Privacy Policy. By providing these links we do not imply that we endorse
          or have reviewed these Third Party Services. Please contact the Third Party Services directly for information
          on their privacy practices and policies.
        </P>
        <P>
          <strong>8. SECURITY</strong>
        </P>
        <P>
          You use the Service at your own risk. We implement commercially reasonable technical, administrative, and
          organizational measures to protect Personal Information both online and offline from loss, misuse, and
          unauthorized access, disclosure, alteration, or destruction. However, no Internet or e-mail transmission is
          ever fully secure or error free. In particular, e-mail sent to or from us may not be secure. Therefore, you
          should take special care in deciding what information you send to us via the Services or e-mail. Please keep
          this in mind when disclosing any Personal Information to Dutchie via the Internet. In addition, we are not
          responsible for circumvention of any privacy settings or security measures contained on the Services, or Third
          Party Services. To learn more about Dutchie's security efforts, please visit{` `}
          <a href='https://trust.dutchie.com/' target='_blank' rel='noreferrer'>
            <span style={{ color: '#0000ff' }}>https://trust.dutchie.com</span>
          </a>
          .
        </P>
        <P>
          <strong>9. YOUR CHOICES</strong>
        </P>
        <P>
          In certain circumstances providing Personal Information is optional. However, if you choose not to provide
          Personal Information that is needed to use some features of our Services, you may be unable to use those
          features. You can also log in to your Dutchie account or <a href='#contact-us'>contact us</a> to request
          updates or corrections to your Personal Information. If you have questions or concerns about other Personal
          Information collected by us or are unable to use your Dutchie account and would like assistance accessing that
          information, please contact our Privacy Officer at{` `}
          <a href='mailto:privacy@dutchie.com'>
            <span style={{ color: '#0075e0' }}>
              <strong>privacy@dutchie.com</strong>
            </span>
          </a>
          .
        </P>
        <P>
          You may opt out of marketing-related communications by following the opt out or unsubscribe instructions
          contained in the marketing communication we send you, or by updating your settings through your account. If
          you unsubscribe from our marketing lists, you will no longer receive marketing communications from us but we
          will continue to contact you to provide the Services, regarding management of your account, other
          administrative matters, and to respond to your requests.
        </P>
        <P>
          <strong>10. CHANGES TO THE PRIVACY POLICY</strong>
        </P>
        <P>
          The Services and our business may change from time to time. As a result, we may change this Privacy Policy at
          any time. When we do, we will post an updated version on this page, unless another type of notice is required
          by applicable law. By continuing to use our Services or providing us with Personal Information after we have
          posted an updated Privacy Policy, you consent to the revised Privacy Policy and practices described in it.
        </P>
        <P>
          {/* eslint-disable-next-line jsx-a11y/anchor-has-content, jsx-a11y/anchor-is-valid  */}
          <a id='contact-us' />
          <strong>11. CONTACT US</strong>
        </P>
        <P>
          If you have any questions or complaints about our Privacy Policy or information practices, or would like to
          request a version of this notice in an accessible format, please feel free to contact us at our designated
          request address:{` `}
          <a href='mailto:privacy@dutchie.com'>
            <span style={{ color: '#0075e0' }}>
              <strong>privacy@dutchie.com</strong>
            </span>
          </a>
          .
        </P>
        <P style={{ 'text-align': 'center' }}>
          <span style={{ textDecoration: 'underline' }}>
            <strong>SCHEDULE A</strong>
          </span>
        </P>
        <P style={{ 'text-align': 'center' }}>
          <strong>Users in California</strong>
        </P>
        <P>
          This Schedule A supplements the information provided in the generally applicable portion of our Privacy Policy
          and applies to individuals that are residents of the State of California and qualify as a "Consumer" under the
          California Consumer Privacy Act of 2018 (as amended by the California Privacy Rights Act of 2020 (
          <strong>"CCPA"</strong>). Any terms defined in the CCPA or as otherwise defined in our Privacy Policy have the
          same meaning as used in this Schedule B. For the avoidance of doubt, when we use the term "Personal
          Information," it means information that identifies, relates to, describes, is reasonably capable of being
          associated with, or could reasonably be linked, directly or indirectly, with a particular consumer or
          household.
        </P>
        <P>
          <strong>1. PERSONAL INFORMATION TABLE</strong>
        </P>
        <P>
          The below table summarizes the categories of Personal Information collected by Dutchie in the past 12 months,
          the sources of collection of the Personal Information, how we use the Personal Information, and the categories
          of Personal Information disclosed by Dutchie for business purposes in the past 12 months. Note that this is an
          aggregate view of all Personal Information, and the Personal Information applicable to you will depend upon
          how you have interacted with Dutchie, the Services, and our Dispensary customers.
        </P>
        <div>
          <Table>
            <Thead>
              <tr>
                <Td>
                  <strong>Category of Personal Information</strong>
                </Td>
                <Td>
                  <strong>Collected</strong>
                </Td>
                <Td>
                  <strong>Categories of sources</strong>
                </Td>
                <Td>
                  <strong>Commercial or business purpose</strong>
                </Td>
                <Td>
                  <strong>How we disclose your Personal Information</strong>
                </Td>
              </tr>
            </Thead>
            <tbody>
              <tr>
                <Td>
                  <strong>Identifiers</strong>
                </Td>
                <Td>Yes</Td>
                <Td>
                  <Tul>
                    <li>
                      <Tp>Provided directly to Dutchie</Tp>
                    </li>
                    <li>
                      <Tp>Automatically collected</Tp>
                    </li>
                    <li>
                      <Tp>Provided by Business Partners</Tp>
                    </li>
                    <li>
                      <Tp>Provided by service providers</Tp>
                    </li>
                    <li>
                      <Tp>Provided by Dispensaries</Tp>
                    </li>
                    <li>
                      <Tp>Provided by Dispensary Employees </Tp>
                    </li>
                  </Tul>
                </Td>
                <Td>
                  <Tul>
                    <li>
                      <Tp>To provide, maintain and support the Services</Tp>
                    </li>
                    <li>
                      <Tp>To manage our business and for internal operational purposes</Tp>
                    </li>
                    <li>
                      <Tp>To market</Tp>
                    </li>
                    <li>
                      <Tp>To personalize your experience</Tp>
                    </li>
                    <li>
                      <Tp>To communicate with you or provide information you have requested</Tp>
                    </li>
                    <li>
                      <Tp>For legal, compliance and security-related purposes</Tp>
                    </li>
                  </Tul>
                </Td>
                <Td>
                  <Tul>
                    <li>
                      <Tp>To Dispensaries and Dispensary Employees</Tp>
                    </li>
                    <li>
                      <Tp>To Business Partners</Tp>
                    </li>
                    <li>
                      <Tp>To service providers</Tp>
                    </li>
                  </Tul>
                </Td>
              </tr>
              <tr>
                <Td>
                  <strong>California Customer Records (Cal. Civ. Code &sect; 1798.80(e))</strong>
                </Td>
                <Td>Yes</Td>
                <Td>
                  <Tul>
                    <li>
                      <Tp>Provided directly to Dutchie</Tp>
                    </li>
                    <li>
                      <Tp>Provided by Business Partners</Tp>
                    </li>
                    <li>
                      <Tp>Provided by service providers</Tp>
                    </li>
                    <li>
                      <Tp>Provided by Dispensaries</Tp>
                    </li>
                    <li>
                      <Tp>Provided by Dispensary Employees</Tp>
                    </li>
                  </Tul>
                </Td>
                <Td>
                  <Tul>
                    <li>
                      <Tp>To provide, maintain and support the Services</Tp>
                    </li>
                    <li>
                      <Tp>To manage our business and for internal operational purposes</Tp>
                    </li>
                    <li>
                      <Tp>To market</Tp>
                    </li>
                    <li>
                      <Tp>To communicate with you or provide information you have requested</Tp>
                    </li>
                    <li>
                      <Tp>For legal, compliance and security-related purposes</Tp>
                    </li>
                  </Tul>
                </Td>
                <Td>
                  <Tul>
                    <li>
                      <Tp>To Dispensaries and Dispensary Employees</Tp>
                    </li>
                    <li>
                      <Tp>To Business Partners</Tp>
                    </li>
                    <li>
                      <Tp>To service providers</Tp>
                    </li>
                  </Tul>
                </Td>
              </tr>
              <tr>
                <Td>
                  <strong>Characteristics of Protected Classifications</strong>
                </Td>
                <Td>No</Td>
                <Td>N/A</Td>
                <Td>N/A</Td>
                <Td>N/A</Td>
              </tr>
              <tr>
                <Td>
                  <strong>Commercial Information</strong>
                </Td>
                <Td>Yes</Td>
                <Td>
                  <Tul>
                    <li>
                      <Tp>Provided directly to Dutchie</Tp>
                    </li>
                    <li>
                      <Tp>Provided by Business Partners</Tp>
                    </li>
                    <li>
                      <Tp>Provided by service providers</Tp>
                    </li>
                    <li>
                      <Tp>Provided by Dispensaries</Tp>
                    </li>
                  </Tul>
                </Td>
                <Td>
                  <Tul>
                    <li>
                      <Tp>To provide, maintain and support the Services</Tp>
                    </li>
                    <li>
                      <Tp>To manage our business and for internal operational purposes</Tp>
                    </li>
                    <li>
                      <Tp>To personalize your experience</Tp>
                    </li>
                    <li>
                      <Tp>For legal, compliance and security-related purposes</Tp>
                    </li>
                  </Tul>
                </Td>
                <Td>
                  <Tul>
                    <li>
                      <Tp>To Dispensaries and Dispensary Employees</Tp>
                    </li>
                    <li>
                      <Tp>To Business Partners</Tp>
                    </li>
                    <li>
                      <Tp>To service providers</Tp>
                    </li>
                  </Tul>
                </Td>
              </tr>
              <tr>
                <Td>
                  <strong>Biometric Information</strong>
                </Td>
                <Td>No</Td>
                <Td>N/A</Td>
                <Td>N/A</Td>
                <Td>N/A</Td>
              </tr>
              <tr>
                <Td>
                  <strong>Internet/Network Information</strong>
                </Td>
                <Td>Yes</Td>
                <Td>
                  <Tul>
                    <li>
                      <Tp>Provided directly to Dutchie</Tp>
                    </li>
                    <li>
                      <Tp>Automatically collected</Tp>
                    </li>
                    <li>
                      <Tp>Provided by service providers</Tp>
                    </li>
                  </Tul>
                </Td>
                <Td>
                  <Tul>
                    <li>
                      <Tp>To provide, maintain and support the Services</Tp>
                    </li>
                    <li>
                      <Tp>To manage our business and for internal operational purposes</Tp>
                    </li>
                    <li>
                      <Tp>To personalize your experience</Tp>
                    </li>
                    <li>
                      <Tp>To market</Tp>
                    </li>
                  </Tul>
                </Td>
                <Td>
                  <Tul>
                    <li>
                      <Tp>To Dispensaries and Dispensary Employees</Tp>
                    </li>
                    <li>
                      <Tp>To service providers</Tp>
                    </li>
                  </Tul>
                </Td>
              </tr>
              <tr>
                <Td>
                  <strong>Geolocation Data</strong>
                </Td>
                <Td>Yes</Td>
                <Td>
                  <Tul>
                    <li>
                      <Tp>Provided directly to Dutchie</Tp>
                    </li>
                    <li>
                      <Tp>Automatically collected</Tp>
                    </li>
                    <li>
                      <Tp>Provided by service providers</Tp>
                    </li>
                  </Tul>
                </Td>
                <Td>
                  <Tul>
                    <li>
                      <Tp>To provide, maintain and support the Services</Tp>
                    </li>
                    <li>
                      <Tp>To personalize your experience</Tp>
                    </li>
                    <li>
                      <Tp>To market</Tp>
                    </li>
                    <li>
                      <Tp>For legal, compliance and security-related purposes</Tp>
                    </li>
                  </Tul>
                </Td>
                <Td>
                  <Tul>
                    <li>
                      <Tp>To Dispensaries and Dispensary Employees</Tp>
                    </li>
                    <li>
                      <Tp>To service providers</Tp>
                    </li>
                  </Tul>
                </Td>
              </tr>
              <tr>
                <Td>
                  <strong>Sensory Information</strong>
                </Td>
                <Td>Yes</Td>
                <Td>
                  <Tul>
                    <li>
                      <Tp>Provided directly to Dutchie</Tp>
                    </li>
                    <li>
                      <Tp>Provided by service providers</Tp>
                    </li>
                  </Tul>
                </Td>
                <Td>
                  <Tul>
                    <li>
                      <Tp>To provide, maintain and support the Services</Tp>
                    </li>
                    <li>
                      <Tp>For legal, compliance and security-related purposes</Tp>
                    </li>
                  </Tul>
                </Td>
                <Td>
                  <Tul>
                    <li>
                      <Tp>To service providers</Tp>
                    </li>
                  </Tul>
                </Td>
              </tr>
              <tr>
                <Td>
                  <strong>Profession/Employment Information</strong>
                </Td>
                <Td>Yes</Td>
                <Td>
                  <Tul>
                    <li>
                      <Tp>Provided by Dispensaries</Tp>
                    </li>
                    <li>
                      <Tp>Provided by Dispensary Employees</Tp>
                    </li>
                  </Tul>
                </Td>
                <Td>
                  <Tul>
                    <li>
                      <Tp>To provide, maintain and support the Services</Tp>
                    </li>
                  </Tul>
                </Td>
                <Td>
                  <Tul>
                    <li>
                      <Tp>To Dispensaries and Dispensary Employees</Tp>
                    </li>
                    <li>
                      <Tp>To service providers</Tp>
                    </li>
                  </Tul>
                </Td>
              </tr>
              <tr>
                <Td>
                  <strong>Non-Public Education Information (20 U.S.C. &sect; 1232g, 34 C.F.R. Part 99)</strong>
                </Td>
                <Td>No</Td>
                <Td>N/A</Td>
                <Td>N/A</Td>
                <Td>N/A</Td>
              </tr>
              <tr>
                <Td>
                  <strong>Inferences</strong>
                </Td>
                <Td>Yes</Td>
                <Td>
                  <Tul>
                    <li>
                      <Tp>Provided directly to Dutchie</Tp>
                    </li>
                    <li>
                      <Tp>Provided by Business Partners</Tp>
                    </li>
                  </Tul>
                </Td>
                <Td>
                  <Tul>
                    <li>
                      <Tp>To provide, maintain and support the Services</Tp>
                    </li>
                    <li>
                      <Tp>To manage our business and for internal operational purposes</Tp>
                    </li>
                    <li>
                      <Tp>To personalize your experience</Tp>
                    </li>
                  </Tul>
                </Td>
                <Td>
                  <Tul>
                    <li>
                      <Tp>To Dispensaries and Dispensary Employees</Tp>
                    </li>
                    <li>
                      <Tp>To service providers</Tp>
                    </li>
                  </Tul>
                </Td>
              </tr>
            </tbody>
          </Table>
        </div>
        <br />
        <P>
          <strong>2. SENSITIVE PERSONAL INFORMATION</strong>
        </P>
        <P>
          As part of the Services, Dutchie collects "sensitive personal information" as defined by the CCPA as part of
          our operations. As mentioned elsewhere in the Privacy Policy, Dutchie may process certain sensitive personal
          information on behalf of a Dispensary, which is not considered for the purpose of this section. Dutchie's
          practices with respect to collected sensitive personal information are described below:
        </P>
        <div>
          <Table>
            <tbody>
              <tr>
                <Td>
                  <strong>Category of Sensitive Personal Information</strong>
                </Td>
                <Td>
                  <strong>Use/Purpose</strong>
                </Td>
                <Td>
                  <strong>Sold?</strong>
                </Td>
                <Td>
                  <strong>Shared?</strong>
                </Td>
              </tr>
              <tr>
                <Td>
                  <strong>Social Security number, driver's license, state ID card or passport number</strong>
                </Td>
                <Td>
                  <Tul>
                    <li>
                      <Tp>To perform underwriting for the cashless payments Services</Tp>
                    </li>
                  </Tul>
                </Td>
                <Td>No</Td>
                <Td>
                  <Tul>
                    <li>
                      <Tp>To service providers</Tp>
                    </li>
                    <li>
                      <Tp>To Business Partners performing the underwriting</Tp>
                    </li>
                  </Tul>
                </Td>
              </tr>
              <tr>
                <Td>
                  <strong>
                    Account log-in, financial account, debit card, or credit card number in combination with any
                    required security access code, password, or credentials allowing access
                  </strong>
                </Td>
                <Td>
                  <Tul>
                    <li>
                      <Tp>To perform the Services</Tp>
                    </li>
                  </Tul>
                </Td>
                <Td>No</Td>
                <Td>
                  <Tul>
                    <li>
                      <Tp>To service providers</Tp>
                    </li>
                  </Tul>
                </Td>
              </tr>
              <tr>
                <Td>
                  <strong>Precise geolocation</strong>
                </Td>
                <Td>
                  <Tul>
                    <li>
                      <Tp>To perform the Services</Tp>
                    </li>
                  </Tul>
                </Td>
                <Td>No</Td>
                <Td>No</Td>
              </tr>
              <tr>
                <Td>
                  <strong>Race or ethnic origin</strong>
                </Td>
                <Td>N/A</Td>
                <Td>N/A</Td>
                <Td>N/A</Td>
              </tr>
              <tr>
                <Td>
                  <strong>Religious or philosophical beliefs</strong>
                </Td>
                <Td>N/A</Td>
                <Td>N/A</Td>
                <Td>N/A</Td>
              </tr>
              <tr>
                <Td>
                  <strong>Union membership</strong>
                </Td>
                <Td>N/A</Td>
                <Td>N/A</Td>
                <Td>N/A</Td>
              </tr>
              <tr>
                <Td>
                  <strong>Mail, email or text message content</strong>
                </Td>
                <Td>N/A</Td>
                <Td>N/A</Td>
                <Td>N/A</Td>
              </tr>
              <tr>
                <Td>
                  <strong>Genetic data</strong>
                </Td>
                <Td>N/A</Td>
                <Td>N/A</Td>
                <Td>N/A</Td>
              </tr>
              <tr>
                <Td>
                  <strong>
                    Processing of biometric information for the purpose of uniquely identifying a consumer
                  </strong>
                </Td>
                <Td>N/A</Td>
                <Td>N/A</Td>
                <Td>N/A</Td>
              </tr>
              <tr>
                <Td>
                  <strong>
                    Personal information collected and anaylzed concerning a consumer's health, sex life or sexual
                    orientation
                  </strong>
                </Td>
                <Td>N/A</Td>
                <Td>N/A</Td>
                <Td>N/A</Td>
              </tr>
            </tbody>
          </Table>
        </div>
        <br />
        <P>
          <strong>3. SALES OF PERSONAL INFORMATION</strong>
        </P>
        <P>Dutchie does not sell Personal Information.</P>
        <P>
          <strong>4. RIGHTS AVAILABLE TO CONSUMERS</strong>
        </P>
        <P>
          The CCPA affords eligible data subjects with certain individual rights with respect to Personal Information
          that we have collected, subject to certain limitations, including:
        </P>
        <ul>
          <li>
            <P>
              <strong>Right to Know:</strong> You have the right to request that a business that collects Personal
              Information from you disclose the following datapoints in relation to you in the preceding 12 month
              period: (a) the specific pieces of Personal Information collected; (b) the Personal Information categories
              collected; (c) the source of categories from which the business collected the Personal Information; (d)
              the Personal Information categories disclosed for a business purpose, if any, and the categories of
              persons receiving that Personal Information; and (e) the business or commercial purpose for collecting,
              selling, or sharing such Personal Information.
            </P>
          </li>
          <li>
            <P>
              <strong>Right of Portability:</strong> You have the right to receive the specific pieces of Personal
              Information the business has collected about you.
            </P>
          </li>
          <li>
            <P>
              <strong>Right of Deletion: </strong>You have the right to request that a business delete your Personal
              Information.
            </P>
          </li>
          <li>
            <P>
              <strong>Right of Correction:</strong> You have the right to request that a business correct any
              inaccuracies in the Personal Information it holds about you, considering both the Personal Information's
              nature and processing purposes.
            </P>
          </li>
          <li>
            <P>
              <strong>Right to Opt-Out of the Sale or Sharing of Personal Information:</strong> You have the right to
              prevent the sale or sharing of Personal Information to third parties. As a reminder, Dutchie does not sell
              your Personal Information.
            </P>
          </li>
          <li>
            <P>
              <strong>Right to Limit Sensitive Personal Information:</strong> You have the right to limit how a business
              uses and discloses your sensitive personal information to the extent it is used for the purpose of
              inferring characteristics about you.
            </P>
          </li>
          <li>
            <P>
              <strong>Right Against Discrimination:</strong> You have the right to not be discriminated or retaliated
              against as a result of exercising any of your rights.
            </P>
          </li>
        </ul>
        <P>
          Your ability to exercise the rights described above are limited pursuant to the scope and limitations of the
          CCPA, including any available exceptions. If the exercise of these rights limits our ability to process
          Personal Information (for example, in the event of a deletion request), we may no longer be able to provide
          you with Services or engage with you in the same manner.
        </P>
        <P>
          Dutchie has established an individual rights portal for the purposes of submitting such individual rights
          requests, which can be found{` `}
          <a href='https://privacy.dutchie.com/' target='_blank' rel='noreferrer'>
            <span style={{ color: '#0000ff' }}>here</span>
          </a>
          . You may also submit requests via email to{` `}
          <a href='mailto:privacy@dutchie.com'>
            <span style={{ color: '#0000ff' }}>privacy@dutchie.com</span>
          </a>
          .
        </P>
        <P>
          Once a request has been submitted, we may ask you for additional information in order to verify your identity
          or to provide additional details to help us respond to your request. This may include your name, email
          address, phone number or other details related to your use of the Services. Where applicable, these requests
          can be submitted by an authorized agent through the channels described above in accordance with the applicable
          law. In these instances, we will take steps to verify the authorized agent's authority to act on your behalf.
        </P>
        <P style={{ 'text-align': 'center' }}>
          <span style={{ textDecoration: 'underline' }}>
            <strong>SCHEDULE B</strong>
          </span>
        </P>
        <P style={{ 'text-align': 'center' }}>
          <strong>Users in Virginia</strong>
        </P>
        <P>
          This Schedule B supplements the information provided in the generally applicable portion of our Privacy Policy
          and applies to individuals that are residents of the State of Virginia and qualify as a "Consumer" under the
          Virginia Consumer Data Protection Act (<strong>"VCDPA"</strong>). Any terms defined in the VCDPA or as
          otherwise defined in our Privacy Policy have the same meaning as used in this Schedule B. For the avoidance of
          doubt, when we use the term "Personal Information," it means "personal data" under the VCDPA, including
          information that is linked or reasonably linkable to an identified or identifiable natural person.
        </P>
        <P>
          <strong>1. RIGHTS AVAILABLE TO CONSUMERS</strong>
        </P>
        <P>
          The VCDPA affords eligible data subjects with certain individual rights with respect to Personal Information
          that we have collected, subject to certain limitations, including:
        </P>
        <ul>
          <li>
            <P>
              <strong>Right of Access:</strong> You have the right to confirm whether a controller is processing your
              Personal Information and to access such information.
            </P>
          </li>
          <li>
            <P>
              <strong>Right of Correction:</strong> You have the right to correct inaccuracies in your Personal
              Information, taking into account the nature of the Personal Information and purposes of the processing.
            </P>
          </li>
          <li>
            <P>
              <strong>Right of Deletion: </strong>You have the right to delete your Personal Information you have
              provided or that has been collected.
            </P>
          </li>
          <li>
            <P>
              <strong>Right of Portability: </strong>You have the right to obtain a copy of your Personal Information
              that was previously provided in a portable, and to the extent technically feasible, readily usable format
              that can be transmitted to another controller where the processing is carried out by automated means.
            </P>
          </li>
          <li>
            <P>
              <strong>Right to Opt Out: </strong>You have the right to opt out of (a) targeted advertising, (b) the sale
              of Personal Information, and (c) profiling in furtherance of decisions that product legal or similarly
              significant effects. Note, however, that Dutchie does not sell your Personal Information.
            </P>
          </li>
        </ul>
        <P>
          Dutchie has established an individual rights portal for the purposes of submitting such individual rights
          requests, which can be found{` `}
          <a href='https://privacy.dutchie.com/' target='_blank' rel='noreferrer'>
            <span style={{ color: '#0000ff' }}>here</span>
          </a>
          . You may also submit requests via email to{` `}
          <a href='mailto:privacy@dutchie.com'>
            <span style={{ color: '#0000ff' }}>privacy@dutchie.com</span>
          </a>
          .
        </P>
        <P>
          Once an individual rights request has been submitted, Dutchie may ask you for additional information in order
          to verify your identity or to provide additional details to help us respond to your request. This may include
          your name, email address, phone number or other details related to your use of the Services. Where applicable,
          these requests can be submitted by an authorized agent through the channels described above in accordance with
          the applicable law. These include requests made on behalf of a minor by the individual's parent or legal
          guardian can also be made via the individual rights portal above. In these cases, in order to verify the
          authorized agent's authority, we generally require evidence of that individual's authority to act on behalf of
          the individual. All individual rights requests will be managed in accordance with the requirements set out in
          the VCDPA.
        </P>
        <P>
          Please note that in certain circumstances, we may refuse to act or impose limitations on your rights, as
          permitted by the applicable law. In the event we decline to take action on a request, we will notify you
          within 45 days of receipt of the original request with our justification for declining to take action and how
          you may appeal that decision (including an overview of the appeals process and how you can initiate an
          appeal).
        </P>
        <P style={{ 'text-align': 'center' }}>
          <span style={{ textDecoration: 'underline' }}>
            <strong>SCHEDULE C</strong>
          </span>
        </P>
        <P style={{ 'text-align': 'center' }}>
          <strong>Users in Colorado</strong>
        </P>
        <P>
          This Schedule C supplements the information provided in the generally applicable portion of our Privacy Policy
          and applies to individuals that are residents of the State of Colorado and qualify as a "Consumer" under the
          Colorado Privacy Act (<strong>"CPA"</strong>). Any terms defined in the CPA or as otherwise defined in our
          Privacy Policy have the same meaning as used in this Schedule C. For the avoidance of doubt, when we use the
          term "Personal Information," it means information that is linked to or reasonably linkable to an identified or
          identifiable individual.
        </P>
        <P>
          <strong>1. RIGHTS AVAILABLE TO CONSUMERS</strong>
        </P>
        <P>
          The CPA affords eligible data subjects with certain individual rights with respect to Personal Information
          that we have collected, subject to certain limitations, including:
        </P>
        <ul>
          <li>
            <P>
              <strong>Right of Access:</strong> You have the right to confirm whether a controller is processing your
              Personal Information and to access such information.
            </P>
          </li>
          <li>
            <P>
              <strong>Right of Correction:</strong> You have the right to correct inaccuracies in your Personal
              Information, taking into account the nature of the Personal Information and purposes of the processing.
            </P>
          </li>
          <li>
            <P>
              <strong>Right of Deletion: </strong>You have the right to delete your Personal Information you have
              provided or that has been collected.
            </P>
          </li>
          <li>
            <P>
              <strong>Right of Portability: </strong>You have the right to obtain a copy of your Personal Information
              that was previously provided in a portable, and to the extent technically feasible, readily usable format
              that can be transmitted to another controller where the processing is carried out by automated means.
            </P>
          </li>
          <li>
            <P>
              <strong>Right to Opt Out: </strong>You have the right to opt out of (a) targeted advertising, (b) the sale
              of Personal Information, and (c) profiling in furtherance of decisions that product legal or similarly
              significant effects. Note, however, that Dutchie does not sell your Personal Information.
            </P>
          </li>
        </ul>
        <P>
          Dutchie has established an individual rights portal for the purposes of submitting such individual rights
          requests, which can be found{` `}
          <a href='https://privacy.dutchie.com/' target='_blank' rel='noreferrer'>
            <span style={{ color: '#0000ff' }}>here</span>
          </a>
          . You may also submit requests via email to{` `}
          <a href='mailto:privacy@dutchie.com'>
            <span style={{ color: '#0000ff' }}>privacy@dutchie.com</span>
          </a>
          .
        </P>
        <P>
          Once an individual rights request has been submitted, Dutchie may ask you for additional information in order
          to verify your identity or to provide additional details to help us respond to your request. This may include
          your name, email address, phone number or other details related to your use of the Services. Where applicable,
          these requests can be submitted by an authorized agent through the channels described above in accordance with
          the applicable law. These include requests made on behalf of a minor by the individual's parent or legal
          guardian can also be made via the individual rights portal above. In these cases, in order to verify the
          authorized agent's authority, we generally require evidence of that individual's authority to act on behalf of
          the individual. All individual rights requests will be managed in accordance with the requirements set out in
          the CPA.
        </P>
        <P>
          Please note that in certain circumstances, we may refuse to act or impose limitations on your rights, as
          permitted by the applicable law. In the event we decline to take action on a request, we will notify you
          within 45 days of receipt of the original request with our justification for declining to take action and how
          you may appeal that decision (including an overview of the appeals process and how you can initiate an
          appeal).
        </P>
        <P style={{ 'text-align': 'center' }}>
          <span style={{ textDecoration: 'underline' }}>
            <strong>SCHEDULE D</strong>
          </span>
        </P>
        <P style={{ 'text-align': 'center' }}>
          <strong>Users in Connecticut</strong>
        </P>
        <P>
          This Schedule D supplements the information provided in the generally applicable portion of our Privacy Policy
          and applies to individuals that are residents of the State of Connecticut and qualify as a "Consumer" under
          the Connecticut Data Privacy Act (<strong>"CTDPA"</strong>). Any terms defined in the CTDPA or as otherwise
          defined in our Privacy Policy have the same meaning as used in this Schedule D. For the avoidance of doubt,
          when we use the term "Personal Information," it means information that is linked to or reasonably linkable to
          an identified or identifiable individual.
        </P>
        <P>
          <strong>1. RIGHTS AVAILABLE TO CONSUMERS</strong>
        </P>
        <P>
          The CTDPA affords eligible data subjects with certain individual rights with respect to Personal Information
          that we have collected, subject to certain limitations, including:
        </P>
        <ul>
          <li>
            <P>
              <strong>Right of Access:</strong> You have the right to confirm whether a controller is processing your
              Personal Information and to access such information.
            </P>
          </li>
          <li>
            <P>
              <strong>Right of Correction:</strong> You have the right to correct inaccuracies in your Personal
              Information, taking into account the nature of the Personal Information and purposes of the processing.
            </P>
          </li>
          <li>
            <P>
              <strong>Right of Deletion: </strong>You have the right to delete your Personal Information you have
              provided or that has been collected.
            </P>
          </li>
          <li>
            <P>
              <strong>Right of Portability: </strong>You have the right to obtain a copy of your Personal Information
              that was previously provided in a portable, and to the extent technically feasible, readily usable format.
            </P>
          </li>
          <li>
            <P>
              <strong>Right to Opt Out: </strong>You have the right to opt out of (a) targeted advertising, (b) the sale
              of Personal Information, and (c) profiling in furtherance of decisions that product legal or similarly
              significant effects. Note, however, that Dutchie does not sell your Personal Information.
            </P>
          </li>
        </ul>
        <P>
          Dutchie has established an individual rights portal for the purposes of submitting such individual rights
          requests, which can be found{` `}
          <a href='https://privacy.dutchie.com/' target='_blank' rel='noreferrer'>
            <span style={{ color: '#0000ff' }}>here</span>
          </a>
          . You may also submit requests via email to{` `}
          <a href='mailto:privacy@dutchie.com'>
            <span style={{ color: '#0000ff' }}>privacy@dutchie.com</span>
          </a>
          .
        </P>
        <P>
          Once an individual rights request has been submitted, Dutchie may ask you for additional information in order
          to verify your identity or to provide additional details to help us respond to your request. This may include
          your name, email address, phone number or other details related to your use of the Services. Where applicable,
          these requests can be submitted by an authorized agent through the channels described above in accordance with
          the applicable law. These include requests made on behalf of a minor by the individual's parent or legal
          guardian can also be made via the individual rights portal above. In these cases, in order to verify the
          authorized agent's authority, we generally require evidence of that individual's authority to act on behalf of
          the individual. All individual rights requests will be managed in accordance with the requirements set out in
          the CTDPA.
        </P>
        <P>
          Please note that in certain circumstances, we may refuse to act or impose limitations on your rights, as
          permitted by the applicable law. In the event we decline to take action on a request, we will notify you
          within 45 days of receipt of the original request with our justification for declining to take action and how
          you may appeal that decision (including an overview of the appeals process and how you can initiate an
          appeal).
        </P>
        <P style={{ 'text-align': 'center' }}>
          <span style={{ textDecoration: 'underline' }}>
            <strong>SCHEDULE E</strong>
          </span>
        </P>
        <P style={{ 'text-align': 'center' }}>
          <strong>Users in Canada</strong>
        </P>
        <P>
          This Schedule E supplements the information provided in the generally applicable portion of our Privacy Policy
          and applies to individuals that are residents of Canada or are otherwise covered under any applicable Canadian
          federal or provincial privacy laws or regulations, including, without limitation the Personal Information
          Protection and Electronic Documents Act (<strong>"PIPEDA"</strong>).
        </P>
        <P>
          <strong>1. CONSENT</strong>
        </P>
        <P>
          By using our Services, you accept the terms of this Privacy Statement and consent to the collection, use,
          processing, disclosure and retention of your Personal information as described in the Privacy Policy.
          Typically, we will provide notice of the purpose for collecting your Personal Information and/or seek your
          consent (which may be express or implied, depending on the nature and sensitivity of the Personal Information)
          in line with applicable law at the time that we collect your Personal information. In general, you may change
          or withdraw your consent at any time subject to legal or contractual obligations and by providing reasonable
          notice. Your withdrawal of consent may impact the ability of Dutchie to provide you with some or all of the
          Services. Upon receiving notice that you would like to withdraw your consent, we will inform you of the likely
          consequences of your withdrawal of consent.
        </P>
        <P>
          Dutchie will not collect, use, or disclose your Personal Information except for the purposes identified in
          this Privacy Policy or as otherwise identified at the time of collection, unless we have received your consent
          (which may be express or implied, depending on the nature and sensitivity of the Personal Information) or the
          processing is authorized without consent.
        </P>
        <P>
          <strong>2. ACCESSING AND CORRECTING YOUR PERSONAL INFORMATION</strong>
        </P>
        <P>
          You may have the right to request access to and to correct the Personal Information that we hold about you,
          subject to certain conditions and limitations. Subject to applicable law and your relationship with us, this
          may include a right to review, correct, update, suppress, delete or otherwise limit our use of your Personal
          Information that has been previously provided to us. You may also have the right to access information about
          the ways in which your Personal Information is or has been used and the names of individuals and/or
          organizations to which your information has been disclosed.
        </P>
        <P>
          Dutchie has established an individual rights portal for the purposes of submitting such individual rights
          requests, which can be found{` `}
          <a href='https://privacy.dutchie.com/' target='_blank' rel='noreferrer'>
            <span style={{ color: '#0000ff' }}>here</span>
          </a>
          . You may also submit requests via email to{` `}
          <a href='mailto:privacy@dutchie.com'>
            <span style={{ color: '#0000ff' }}>privacy@dutchie.com</span>
          </a>
          .
        </P>
        <P>
          We will respond to your request as soon as reasonably practicable, and within the time period required by law.
          You will not be charged to exercise these rights. Once an individual rights request has been submitted,
          Dutchie may ask you for additional information in order to verify your identity or to provide additional
          details to help us respond to your request. This may include your name, email address, phone number or other
          details related to your use of Dutchie's Services.
        </P>
        <P>
          If we correct your information, we will also send the corrected information to organizations to which we
          disclosed the information during the year before the date the correction was made.
        </P>
        <P>
          Please note that in certain circumstances, we may refuse to act or impose limitations on your rights, as
          permitted by applicable law. If we cannot provide you with access to your Personal Information or are unable
          to correct your Personal Information, we will inform you of the reasons why, subject to any legal
          restrictions, and outline further steps available to you. If we refuse to act on a request to correct your
          Personal Information, we will nonetheless annotate the information, noting the correction that was requested
          but not made.
        </P>
        <P>
          In certain cases, depending on the nature of your request, there may also be residual information that will
          remain within our databases and other records, which, due to applicable law or as part of Services that are in
          the process of being carried out, will not be removed or changed. We will also retain information relating to
          your request for recordkeeping and compliance purposes.
        </P>
        <P>
          <strong>3. TRANSFERS</strong>
        </P>
        <P>
          We may process, store, and transfer your Personal Information in and to a foreign country, with different
          privacy laws that may or may not be as comprehensive as Canadian law, including the United States. In these
          circumstances, the governments, courts, law enforcement, or regulatory agencies of that country may be able to
          obtain access to your personal information through the laws of the foreign country. When you provide Personal
          Information to us through the Services, you consent to the transfer and processing of your Personal
          Information in this manner. Any international transfers made will be in accordance with applicable law.
        </P>
        <P>
          We also impose appropriate safeguards for the transfer of Personal Information among our affiliates and to
          third-party service providers in various jurisdictions, and have implemented appropriate contractual
          arrangements or other measures for such purposes.
        </P>
      </PageContentContainer>
      <GlobalFooter />
    </Wrapper>
  );
}

export const Table = styled.table`
  border: 1px solid;
  border-collapse: collapse;
  table-layout: fixed;
`;

export const Thead = styled.thead`
  background-color: #d4d4d4;
`;

export const Td = styled.td`
  border: 1px solid;
  padding: 3px 3px 3px 12px;
  vertical-align: top;
  width: 20%;
`;

export const Tul = styled.ul`
  margin: 0;
  padding-left: 20px;
`;

export const Tp = styled.p`
  margin-bottom: 4px;
`;
